<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <TextInput
                  v-model="form.name"
                  :label="$t('Name')"
                  :tooltipMessage="$t('Name')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Credits"
                rules="required"
              >
                <TextInput
                  v-model="form.credits"
                  type="number"
                  :label="$t('Credits')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Costs"
                rules="required"
              >
                <TextInput
                  v-model="form.costs"
                  type="number"
                  :label="$t('Costs')"
                  :required="true"
                  :isRightIcon="true"
                  :rightIconName="'EuroIcon'"
                />
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <label class="input-label form-label">{{ $t("Image") }} </label>
              <input
                type="file"
                @change="addFile($event, 'image')"
                accept="image/*"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end gap-2">
        <router-link
          to="/packages"
          class="d-flex align-items-center gap-1 btn btn-secondary"
        >
          <feather-icon icon="XIcon" size="12" />
          <span>{{ $t("Cancel") }}</span>
        </router-link>
        <b-button
          variant="primary"
          @click="createPackage"
          class="d-flex align-items-center gap-1"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Create Package") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";

export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Packages"),
          to: "/packages",
        },
        {
          text: this.$t("Create"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      form: {
        name: "",
        credits: "",
        costs: "",
        image: {},
      },
    };
  },
  methods: {
    addFile(event, type) {
      const file = event.target.files[0];
      if (file instanceof Blob) {
        let reader = new FileReader();

        const readFileAsBase64 = () => {
          return new Promise((resolve, reject) => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              const requiredData = reader.result;
              const data = {
                name: file.name,
                size: file.size,
                base64: requiredData,
              };
              resolve(data);
            };
            reader.onerror = (error) => {
              reject(error);
            };
          });
        };
        (async () => {
          try {
            const data = await readFileAsBase64();
            this.form.image = data;
          } catch (error) {
            console.error("Error reading file:", error);
          }
        })();
      }
    },
    async createPackage() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          try {
            this.$store.commit("showLoader", true);
            await this.$store.dispatch("packages/create", {
              ...this.form,
            });
            this.$router.push("/packages");
          } catch (e) {
            console.error("api error ___", e);
          } finally {
            this.$store.commit("showLoader", false);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
